'use strict';

var base = require('base/product/base');
var focusHelper = require('base/components/focus');
var scrollAnimate = require('base/components/scrollAnimate');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Clears any currently displayed error messages
 */
function clearErrorNotification() {
    var errorList = $('.p-cart .p-cart__shop').find('.c-list.-alert');
    if (errorList.length > 0) {
        $(errorList).remove();
    }
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    clearErrorNotification()
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
        $('.checkout-btn').prop("disabled", true);
    } else {
        $('.checkout-btn').removeClass('disabled');
        $('.checkout-btn').prop("disabled", false);
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.miraklShippingPriceFormatted);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });

    // Update amount until free shipping (Cart)
    var $freeShipping = $('.p-cart .p-cart__shop .c-total .c-attention.free-shipping');
    if ($freeShipping.length > 0) {
        $freeShipping.remove();
        $('.p-cart .p-cart__shop .c-total').addClass('no-free-ship-msg');
    }
    if (data.renderAmountUntilFreeShipping) {
        Object.keys(data.renderAmountUntilFreeShipping).forEach(function (shopId) {
            if (data.amountUntilFreeShipping[shopId] > 0) {
                $('.p-cart #' + shopId + '.p-cart__shop .c-total .c-title')
                    .before('<span class="c-attention free-shipping"></span>');
                $('.p-cart #' + shopId + '.p-cart__shop .c-total .c-attention.free-shipping')
                    .html(data.renderAmountUntilFreeShipping[shopId]);
                $('.p-cart #' + shopId + '.p-cart__shop .c-total').removeClass('no-free-ship-msg');
            }
        });
    }

    if (data.totals.subTotalPerShop) {
        Object.keys(data.totals.subTotalPerShop).forEach(function (shopId) {
            $('.sub-total', '#' + shopId).empty().append(data.totals.subTotalPerShop[shopId]);
        });
    }

    // Update points to be awarded (Cart)
    if (data.pointsToBeAwarded) {
        Object.keys(data.pointsToBeAwarded).forEach(function (shopId) {
            $('.c-point .c-num', '#' + shopId).html(data.pointsToBeAwarded[shopId]
                .toLocaleString(undefined, ));
        });
    }

    // Update Coupon Usage
    if (data.totals.discountTotal.value === 0) {
        $('.coupon-summary').addClass('d-none');
    } else {
        $('.coupon-summary').removeClass('d-none');
        $('.coupon-total').empty().append(data.totals.discountTotal.formatted);
    }
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    // Update Point Usage
    if (data.totals.pointApplyTotal.value === 0) {
        $('.point-summary').addClass('d-none');
    } else {
        $('.point-summary').removeClass('d-none');
        $('.point-total').empty().append(data.totals.pointApplyTotal.formatted);
    }

    // Update Award Point
    if (data.pointAward){
        $('.point-award').empty().append(data.pointAward);
    }

    // Update Payment Selection
    if (data.totals.grandTotalValue === 0) {
        $('.order-zero').addClass('d-none');
        $('.order-full-point').removeClass('d-none');
        $('.order-payment > .is-open').removeClass('is-open');
        $('.order-full-point > .fn-accordion').addClass('is-open');
    } else if ($('.order-full-point > .fn-accordion').hasClass('is-open')){
        $('.order-zero').removeClass('d-none');
        $('.order-full-point').addClass('d-none');
        $('.order-full-point > .fn-accordion').removeClass('is-open');
        $('.order-card-payment > .fn-accordion').addClass('is-open');
        $('.order-payment .p-accordion_bar__content').css('display', 'none');
        $('.order-card-payment .p-accordion_bar__content').css('display', 'block');
    }

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }
        $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
        $('.price-total-' + item.UUID).empty().append(item.priceTotal.nonAdjustedPrice);
        $('.shipping-cost-' + item.UUID).empty().append(item.shippingPriceFormatted);
    });
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(response) {
    var errorHtml = '';
    if (response.errorProductName) {
        errorHtml = '<dl class="c-list -alert mt-3">' +
        '<div class="c-scope">' +
        '<dt>' + response.errorMessage + '</dt>' +
        '<dd>' + response.errorProductName + '</dd>' +
        '</div>' +
        '</dl>';
    } else {
        errorHtml = '<dl class="c-list -alert mt-3">' +
        '<div class="c-scope">' +
        '<dt>' + response.errorMessage + '</dt>' +
        '</div>' +
        '</dl>';
    }

    if (response.cartShopId) {
        $('.p-cart #' + response.cartShopId + '.p-cart__shop .c-heading.btm-border').after(errorHtml);
    } else {
        $('.p-cart .p-cart__shop .c-heading.btm-border').after(errorHtml);
    }

    scrollAnimate();
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $('.availability-' + lineItem.UUID).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach(function (message) {
                    messages += '<p class="line-item-attributes">' + message + '</p>';
                });
            }

            if (lineItem.availability.inStockDate) {
                messages += '<p class="line-item-attributes line-item-instock-date">'
                    + lineItem.availability.inStockDate
                    + '</p>';
            }
        }

        $('.availability-' + lineItem.UUID).html(messages);
    }
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) { // eslint-disable-line no-unused-vars
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    $('.card.product-info.uuid-' + uuid).replaceWith(data.renderedTemplate);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $('.c-btn').attr('disabled', true);
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            $('.c-btn').attr('disabled', false);
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            $('body').trigger('editproductmodal:ready');
            $.spinner().stop();
        },
        error: function () {
            $('.c-btn').attr('disabled', false);
            $.spinner().stop();
        }
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);
    $deleteConfirmBtn.data('name', productName);

    $productToRemoveSpan.empty().append(productName);
}

/**
 * Creates an error notification for the coupon
 * @param {string} message Error message
 */
function createCouponNotification(message){
    var errorHtml = '<dt>' + message + '</dt>';
    $('.order-coupon-error').empty().append(errorHtml);
    $('.order-coupon-error').removeClass('d-none');
}

/**
 * Remove Coupon Error
 */
function removeCouponError(){
    $('.order-coupon-error').empty();
    $('.order-coupon-error').addClass('d-none');
}

/**
 * Initialization of delete button for the coupon
 */
function couponDeleteInit(){
    $('.delete-coupon').on('click', function (e) {
        e.preventDefault();

        var url = $(this).data('url');
        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $('body').trigger('promotion:beforeUpdate');
        $('.delete-coupon').attr('disabled', true);
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.delete-coupon').attr('disabled', false);
                removeCouponError()
                if (data.totals.couponDiscount.count === 0){
                    $('.fn-coupon_list').addClass('d-none');
                }
                $('.fn-coupon_list').empty().append(data.totals.discountsHtml);
                couponDeleteInit();
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                $.spinner().stop();
                $('body').trigger('promotion:success', data);
            },
            error: function (err) {
                $('.delete-coupon').attr('disabled', false);
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createCouponNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
}

/**
 * Disable all combo box for quantity per given Cart Division
 */
function disableAllQuantityCmbBoxPerCartDiv(cartDivisionId) {
    $('.p-cart #' + cartDivisionId + '.p-cart__shop .quantity-form > .quantity').attr('disabled', true);
}

module.exports = function () {
    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        confirmDelete(actionUrl, productID, productName, uuid);
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('.optional-promo').click(function (e) {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var name = $(this).data('name');
        var urlParams = {
            pid: productID,
            uuid: uuid,
            name: name
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $('.cart-delete-confirmation-btn').attr('disabled', true);
        $('.remove-product').attr('disabled', true);
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.cart-delete-confirmation-btn').attr('disabled', false);
                if (data.basket.items.length === 0) {
                    $('.cart.cart-page').empty();
                    $('.cart.cart-page').parent().append(data.renderCartEmpty);
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }
                    $('.uuid-' + uuid).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    updateCartTotals(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);

                    var idArray = [];
                    $('.p-cart__shop').each(function () {
                        idArray.push(this.id);
                    });

                    Object.keys(data.renderProductCardList).forEach(function (shopId) {
                        // Redisplay product list
                        $('.p-cart #' + shopId + '.p-cart__shop .p-cart__goods > .p-cart__item').remove()
                        $('.p-cart #' + shopId + '.p-cart__shop .p-cart__goods > .p-cart__header')
                            .after(data.renderProductCardList[shopId]);
                        idArray = idArray.filter(function(item) {
                            return item !== shopId
                        });
                    });

                    if (idArray.length !== 0) {
                        idArray.forEach(function (shopId) {
                            if ($('.p-cart__shop').length === 1) {
                                location.reload();
                            } else {
                                $('#' + shopId + '.p-cart__shop').remove();
                            }
                        });
                    }

                    $('button.fn-modal.remove-product').on('click', function() {
                        setTimeout(function() {
                            $('#MODAL_DELETE_ITEM').css('opacity', 100);
                            $('#MODAL_DELETE_ITEM').css('display', 'flex');
                        }, 220);
                    });

                    // Clear error messages
                    clearErrorNotification();

                    // Redisplay shop name
                    if (data.shopName) {
                        if ($('.p-cart .p-cart__shop > .c-heading.-lv2').length > 0) {
                            $('.p-cart .p-cart__shop > .c-heading.-lv2').remove();
                        }
                        $('.p-cart .p-cart__shop .p-cart__goods').before(
                            '<h2 class="c-heading -lv2">' + data.shopName + '</h2>'
                        );
                    }

                    validateBasket(data.basket);

                    Object.keys(data.hasAgeResrictedProduct).forEach(function (shopId) {
                        $('input[name="hasAgeResrictedProduct"].' + shopId).val(data.hasAgeResrictedProduct[shopId]);
                    });

                    // Redisplay error messages
                    if (data.renderCartErrors) {
                        Object.keys(data.renderCartErrors).forEach(function (shopId) {
                            $('.p-cart #' + shopId + '.p-cart__shop > .c-heading.btm-border')
                                .after(data.renderCartErrors[shopId]);

                            if (data.valid[shopId].isDisabled) {
                                $('button.checkout-btn.' + shopId).attr('disabled', true);
                            }
                        });
                    }
                }

                disableAllQuantityCmbBoxPerCartDiv('main');
                $('body').trigger('cart:update', data);

                $.spinner().stop();
            },
            error: function (err) {
                $('.cart-delete-confirmation-btn').attr('disabled', false);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('change', '.quantity-form > .quantity', function () {
        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $('.quantity').attr('disabled', true);
        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity').attr('disabled', false);
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                updateAvailability(data, uuid);
                validateBasket(data);
                $(this).data('pre-select-qty', quantity);

                $('body').trigger('cart:update', data);

                // Redisplay error messages
                if (data.renderCartErrors) {
                    Object.keys(data.renderCartErrors).forEach(function (shopId) {
                        $('.p-cart #' + shopId + '.p-cart__shop > .c-heading.btm-border')
                            .after(data.renderCartErrors[shopId]);

                        if (data.valid[shopId].isDisabled) {
                            $('button.checkout-btn.' + shopId).attr('disabled', true);
                        }
                    });
                }

                $.spinner().stop();
                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    location.reload();
                }
            },
            error: function (err) {
                $('.quantity').attr('disabled', false);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $('body').trigger('cart:beforeShippingMethodSelected');
        $('.c-btn').attr('disabled', true);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                $('.c-btn').attr('disabled', false);
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }

                $('body').trigger('cart:shippingMethodSelected', data);
                $.spinner().stop();
            },
            error: function (err) {
                $('.c-btn').attr('disabled', false);
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();
        $('body').trigger('promotion:beforeUpdate');

        $('#couponCodeBtn').attr('disabled', true);
        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                $('#couponCodeBtn').attr('disabled', false);
                if (data.error) {
                    createCouponNotification(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                } else {
                    removeCouponError();
                    $('.fn-coupon_list').removeClass('d-none');
                    $('.fn-coupon_list').empty().append(data.totals.discountsHtml);
                    couponDeleteInit();
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    $('body').trigger('promotion:success', data);
                }
                $('.coupon-code-field').val('');
                $('#couponCodeBtn').prop('disabled', true);
                $.spinner().stop();
            },
            error: function (err) {
                $('#couponCodeBtn').attr('disabled', false);
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createCouponNotification(err.errorMessage.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    couponDeleteInit();

    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $('.bonus-product-button').attr('disabled', true);
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                $('.bonus-product-button').attr('disabled', false);
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $('.bonus-product-button').attr('disabled', false);
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('shown.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr('disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available')
        );
    });

    $('body').on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);


        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('available'); });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('ready-to-order'); });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('change', '.options-select', function () {
        var selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
        var uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId
        };

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        if (updateProductUrl) {
            $('.update-cart-product-global').attr('disabled', true);
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('.update-cart-product-global').attr('disabled', false);
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    validateBasket(data.cartModel);

                    $('body').trigger('cart:update', data);

                    $.spinner().stop();
                },
                error: function (err) {
                    $('.update-cart-product-global').attr('disabled', false);
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $('button.checkout-btn').on('click', function () {
        var shopId = $(this).parent().parent().parent().parent().parent().parent().attr('id');

        if ($('input[name="hasAgeResrictedProduct"].' + shopId).val() === 'true') {
            setTimeout(function() {
                $('#MODAL_AGE_RESTRICTION').css('opacity', 100);
                $('#MODAL_AGE_RESTRICTION').css('display', 'flex');
                $('#MODAL_AGE_RESTRICTION #targetShopId').val(shopId);
            }, 220);
        } else {
            window.location.href = $(this).attr('href');
        }
    });

    $('input[name="ageConsentConfirm"]', '#MODAL_AGE_RESTRICTION').on('change', function () {
        if ($('input[name="ageConsentConfirm"]').is(':checked')) {
            $('button.c-btn.-primary', '#MODAL_AGE_RESTRICTION').prop('disabled', false);
        } else {
            $('button.c-btn.-primary', '#MODAL_AGE_RESTRICTION').prop('disabled', true);
        }
    })

    $('input[name="ageConsentConfirm"]').prop('checked', false);

    disableAllQuantityCmbBoxPerCartDiv('main');

    $('button.c-btn.-primary', '#MODAL_AGE_RESTRICTION').on('click', function () {
        var cartShopId = $('#MODAL_AGE_RESTRICTION #targetShopId').val();

        if ($('input[name="hasAgeResrictedProduct"].' + cartShopId).val() === 'true') {
            clearErrorNotification();
            $('.c-btn').attr('disabled', true);
            $.ajax({
                url: $(this).attr('href') + '?cartShopId=' + cartShopId,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    $('.c-btn').attr('disabled', false);
                    window.location.href = $('button.checkout-btn.' + cartShopId).attr('href');
                },
                error: function (err) {
                    $('.c-btn').attr('disabled', false);
                    $('#MODAL_AGE_RESTRICTION').hide();
                    createErrorNotification(err.responseJSON);
                }
            });
        } else {
            window.location.href = $('button.checkout-btn').attr('href');
        }
    })

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
};
