'use strict';

module.exports = function () {
    $('.formLogoutSfcc').on('click', function() {
        const $form = $(this);
        $('.c-btn').attr('disabled', true);

        $.ajax({
            url: $form.attr('action'),
            method: 'GET',
            dataType: 'json',
            data: $form.serialize(),
        }).done(function (data) {
            if (data.isLoggedOut) {
                window.location.href = data.redirectUrl;
            } else {
                $('.c-btn').attr('disabled', false);
                // Request is submitted to the authentication infrastructure in the form of a logout request form.
                $('#formLogoutAuthenticationInfrastructure').trigger('submit');
            }
        }).fail(function (err) {
            $('.c-btn').attr('disabled', false);

            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                window.location.href = $('[name=errorRedirectUrl]').val();
            }
        });
    });
};