/**
* @module app_jrwest/cartridge/client/default/js/components/errorScroll
* @author SCSK Corporation
* @copyright (C) 2023 SCSK Corporation
*/

'use strict';

/**
 * Scroll on error
 * @param {Object} focus Object to Focus
 */
module.exports = function (focus) {
    let areaTop = 0;
    if ($(window).width() < 768) {
        areaTop = 100;
    } else {
        areaTop = 150;
    }
    $(() => {
        let top = 0;
        $('.is-invalid').each((index, element) => {
            let newTop = $(element).parent().offset().top;
            if(top === 0 || top > newTop) {
                top = newTop - areaTop;
            }
        });
        if (focus) {
            var focusTop = $(focus).parent().offset().top;
            if(top === 0 || top > focusTop) {
                top = focusTop - areaTop;
            }
        }
        $('html, body').animate({
            scrollTop: top
        }, 'fast');
    });
};
